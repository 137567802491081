import styles from "../../../styles/module/news2.module.css";
import React, { useCallback } from "react";
import api from "../../../services/api";
import Loading from "../others/Loading";
import { useQuery } from "react-query";
import { imagemURL, urlADM, urlEmDeploy } from "../../../services/variables";
import { PiEyesFill } from "react-icons/pi";
import { MdDateRange } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import CarouselImageNews from "../img/CarouselImageNews";

export default function News(props) {
  const idNoticia = props.idNoticias;

  const generateWhatsAppLink = useCallback(() => {
    return `${urlEmDeploy}/noticia/${idNoticia}`;
  }, [idNoticia]);

  const copyToClipboard = useCallback((text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => alert("Link copiado para a área de transferência!"))
      .catch((err) => console.error("Erro ao copiar o link: ", err));
  }, []);

  const { data, isLoading, isError } = useQuery(
    `news-${idNoticia}`,
    async () => {
      const response = await api.post(`/news/unique/${idNoticia}`);
      return response.data.res;
    },
    { staleTime: 5 * 60 * 1000, cacheTime: 10 * 60 * 1000 }
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <p>Error loading news...</p>;
  }

  return (
    <div className={styles.container}>
      {data &&
        data.map((news) => (
          <div key={news.ID} className={styles.newsItem}>
            <div className={`${styles.header} ${styles.ajuste}`}>
              {/* <img alt="notícias" src="/icons/seta-direita.svg" /> */}
              <h2 className={styles.title}>{news.TITULO}</h2>
            </div>
            <div className={`${styles.metaInfo} ${styles.ajuste}`}>
              <p className={styles.category}>Categoria: {news.CATEGORIA}</p>
              <p className={styles.date}>
                <MdDateRange /> Data:{" "}
                {new Date(news.DATA_POST).toLocaleDateString("pt-br")}
              </p>
              <p className={styles.views}>
                {news.VISUALIZACAO} visualizações <PiEyesFill size={18} />
              </p>
            </div>

            {/* teste */}
            <div className={`${styles.share_social} ${styles.ajuste}`}>
              <button
                onClick={() => copyToClipboard(generateWhatsAppLink())}
                className={styles.whats}
              >
                {" "}
                <FaWhatsapp className={styles.whats_icon} />{" "}
              </button>
              {/* <button
                onClick={() => copyToClipboard(generateWhatsAppLink())}
                className={styles.face}
              ><FaFacebook className={styles.face_icon} /></button> */}
              <button
                onClick={() => {
                  const pageUrl = encodeURIComponent(window.location.href); 
                  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`;
                  window.open(
                    facebookShareUrl,
                    "_blank",
                    "width=600,height=400"
                  ); 
                }}
                className={styles.face}
              >
                <FaFacebook className={styles.face_icon} />
              </button>
            </div>
            {/* fim teste */}

            <div className={styles.imageWrapper}>
              <img
                width={360}
                alt="imagem da notícia"
                src={
                  news.IMG === "logo.jpg"
                    ? "/imagens/logo.png"
                    : imagemURL + news.IMG
                }
              />
            </div>
            <div
              className={`${styles.content} ${styles.ajuste}`}
              dangerouslySetInnerHTML={{ __html: news.CORPO_TEXTO }}
            />
            
            {news.ARQUIVO && (
              <a
                className={styles.anexo_file}
                target="_blank"
                rel="noreferrer"
                href={
                  urlADM +
                  `/downloads/other?n=${news.CNPJ.replaceAll("-", "")
                    .replaceAll("/", "")
                    .replaceAll(".", "")}&a=${news.ARQUIVO}&p=noticia`
                }
              >
                Clique aqui para baixar o anexo
              </a>
            )}

            {news.VIDEO && (
              <div className={styles.videoWrapper}>
                <iframe
                  width="560"
                  height="315"
                  className={styles.video}
                  src={news.VIDEO}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            )}

            <CarouselImageNews img={news.MULTIPLE_IMG} />
            {/* <div className={styles.shareSection}>
              <button
                onClick={() => copyToClipboard(generateWhatsAppLink())}
                className={styles.whatsappButton}
              >
                Compartilhe esta notícia
              </button>
            </div> */}
          </div>
        ))}
    </div>
  );
}