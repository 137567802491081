import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import Footer from "../../../components/public/others/Footer";
import UniqueNew from "../../../components/public/news/UniqueNewsSystem"
import { useParams } from "react-router-dom";
import { TipoPortal } from "../../../services/variables";
import { useEffect, useState } from "react";
import Seletivo from "../../../components/public/news/Seletivo";

export default function PageUniqueNewsSytem(props) {
  
  const [slugGet,setSlug] = useState(props.slug)

  const {slugURL} = useParams()

    if(slugGet == '' ){
      setSlug(slugURL.toLowerCase())
      
    }

  console.log(slugURL + "pages") 
  
  return (

    <>
     {TipoPortal == 3 ? <>
      <NavMenu/>   
    </>:<>
      <NavMenuCM/>
    </>} 
      <Seletivo slugNewsSystem={slugGet}/>      
      <Footer/>
    </>
  )
}
